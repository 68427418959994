.mobileContainer {
  display: flex;
  flex-wrap: wrap;
  // height: 100vh;
  // overflow: auto;
  justify-content: center;
  background-position: center center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 100vh;
}

.mobileContent {
  max-width: 500px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.backgroundOverlayColor {
  background-color: var(--background-overlay-color);
  background-image: none;
}
