.buttonStyle {
  &.template1 {
    border-width: 1px !important;
    background: none;
    border-color: var(--heading-color-6);

    svg {
      fill: var(--heading-color-6);

      path {
        fill: var(--heading-color-6);
      }
    }
  }

  &.template2 {
    border-width: 1px !important;
    background-color: var(--button-bg-color-6);
    border-color: var(--button-border-color-6);
    //box-shadow: var(--button-box-soft-shadow),

  }

  &.template3 {
    border-width: 1px !important;
    background-color: var(--button-bg-color-6);
    border-color: var(--button-border-color-6);
    box-shadow: var(--button-box-hard-shadow),
  }
}
