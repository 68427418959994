.area1.area2.activeButton{
    div{
        font-size: 12px;
    }
    a:active {
    background-color: #F5F5F5 !important;
}}
 .fontSize {
     font-size: 18px !important;
 }

.rounded{
    border-radius: 4px !important;
    a{
        border-radius: 4px !important;
    }
 }