.notShowing {
    height: 0;
    padding: 0;
    transition: height .2s ease-in-out;
    button {
        display: none;
    }
}

.showing {
    height: 56px;
    padding: 0.5rem;
    transition: height .2s ease-in-out;
    button {
        display: block;
    }
}