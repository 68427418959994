.labelSettingBox{
  background: url("https://image.passio.eco/page-builder/page-builder/settings/header.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.crown{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 999px;
  margin-bottom: 4px !important;
  pointer-events: none;
  .btnTextSpan{
    font-weight: 500;
    line-height: 140%;
    font-size: 13px;
  }
}
.domain{
  color: #fff;
  font-size: 16px;
  line-height: 130%;
}
.headerRight{
  width: calc(100% - 72px);
}
.btnEditDomain{
  padding: 6px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}
.wrapperPremium{
  padding: 12px 16px 8px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  overflow: hidden;
}
.itemSwiperPremium{
  display: flex;
  gap: 8px;
  align-items: center;
  width: 70%;
  max-width: calc(100% - 78px);
  padding-bottom: 10px;
}
.titleItem{
  font-size: 16px;
  line-height: 130%;
  color: #1F1F1F;
}
.descriptionItem{
  font-size: 12px;
  line-height: 16px;
  /* Theme/Weak */
  color: var(--adm-color-weak);
}
.icon{
  width: 40px;
  height: 40px;
  border: 1.5px solid #FA8C16;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnUpgradeBox{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background: #fff;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}
.btnUpgrade{
  font-weight: 500;
  line-height: 140%;
  padding: 5px 10px;
}
.introItem{
  max-width: calc(100% - 48px);
}
.action{
  background: #FFFFFF;
  box-shadow: 0px -4px 4px rgb(0 0 0 / 5%);
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  position: relative;
  z-index: 9;
}
.actionItem{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10.5px;
}
.actionItem:after {
  content: "";
  width: 0.5px;
  height: 100%;
  background-color: #EEEEEE;
  right: -8px;
  position: absolute;
  top: 0;
}
.action .actionItem:last-child:after{
  content: none;
}
.textAction{
  font-size: 13px;
  line-height: 131%;
  color: var(--adm-color-weak);;
}